






























import Vue from 'vue'
import { MDCDialog } from '@material/dialog'

export default Vue.extend({
	name: 'Modal',
	props: {
		title: String,
		open: Boolean,
		sticky: Boolean,
		scrollable: Boolean,
	},
	data: () : {
		dialog: MDCDialog | any,
	} => {
		return {
			dialog: undefined,
		}
	},
	computed: {
		hasTitle(): boolean {
			return Boolean(this.title || this.$slots.title)
		},
	},
	mounted() {
		this.dialog = new MDCDialog(this.$el)
		if (this.open) {
			this.dialog.open()
		}
		if (this.sticky) {
			this.dialog.scrimClickAction = ''
			this.dialog.escapeKeyAction = ''
		}
		this.dialog.listen('MDCDialog:closed', () => this.$emit('close'))
		if (this.scrollable) {
			this.$el.classList.add('mdc-dialog--scrollable')
		}
	},
	methods: {
		close() {
			this.dialog.close()
		},
	},
	watch: {
		open(newVal: boolean): void {
			if (newVal) {
				this.dialog.open()
			} else {
				this.dialog.close()
			}
		}
	}

})
