






import Vue from 'vue'

export default Vue.extend({
	name: 'Card',
	props: {
		loading: Boolean,
	},
})
