



































import Vue from 'vue'
import { MDCTabBar } from '@material/tab-bar'

export default Vue.extend({
	name: 'TabBar',
	props: {
		items: {
			type: Array,
			default: () => ([]),
		},
		selectedIndex: {
			type: Number,
			default: 0,
		},
	},
	data: () : {
		tabBar: MDCTabBar | any
	} => {
		return {
			tabBar: undefined,
		}
	},
	mounted() {
		this.tabBar = new MDCTabBar(this.$el)
	},
})
