













import Vue from 'vue'

export default Vue.extend({
	name: 'LoadingBar',
	props: {
		visible: {
			type: Boolean,
			default: false,
		},
		relative: {
			type: Boolean,
			default: false,
		},
	}
})
