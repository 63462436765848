






import Vue from 'vue'

export default Vue.extend({
	name: 'StatusChip',
	props: {
		status: String
	}
})
